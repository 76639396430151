import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Image} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {ErrorToast, SuccessToast, WarningToast} from "../../../utils/toasters";
import { IPackages } from '../../../model/package/IPackage';
import {multipartFormData} from "../../../utils/api.service";
import { PackagesService } from '../PackagesService';
import { PACKAGES_URL } from '../../../utils/Endpoints';
import { useSelector } from 'react-redux';
import { getCorrectEnums } from '../../../utils/Functions';
import {calculateFee} from "../../../helpers/FeeCaluculation";

export default function PackagesForm() {
    const {
      PackagesEnum,
      SocialPackageEnum,
      SearchPackageEnum,
      BriefingPackageEnum,
      BookPackageEnum,
      ManagePackageEnum,
      MeasurePackageEnum,
      MessagePackageEnum,
      SuccessPackageEnum,
      CustomerPackageEnum,
      TechSupportPackageEnum,
      CreatorSourcingPackageEnum,
    } = useSelector((state: any) => state.enums);

    const {state: {id, isEdit}}: any = useLocation();
    const history = useHistory();
    const [packageData, setPackageData] = useState<Partial <IPackages>>({
        isActive: false,
        name: 'small',
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImage] = useState('')

    useEffect(() => {
        if (isEdit) {
            PackagesService.getPackage(id)
                .then((response) => {
                    setPackageData(response);
                    response?.image && setImage(response?.image?.path)
                }).catch(err => ErrorToast(err))
        }
    }, [])

    const createPackage = () => {
        if (!!packageData.name) {
            multipartFormData(selectedFile, PACKAGES_URL, JSON.stringify(packageData), 'post')
                .then(response => {
                    SuccessToast('Successfully created package')
                    history.push("/packages")
                }).catch(error => ErrorToast(error))
        } else {
            WarningToast('Please fill out all mandatory fields');
        }
    }

    const updatePackage = () => {
        multipartFormData(selectedFile, `${PACKAGES_URL}/${id}`, JSON.stringify(packageData))
            .then(response => {
                SuccessToast('Successfully updated package')
                history.push("/packages")
            }).catch(error => ErrorToast(error))
    }

    function handleChange(event: any) {
        const {value, name} = event.target;
        setPackageData({
            ...packageData,
            [name]: name === 'fee' && (+value > 1 || +value < 0 ) ? 1 : value
        });
    }

    function handleFileInput(evt: any) {
        if (evt.target.files.length > 0) {
            setSelectedFile(evt.target.files[0]);
            setImage(URL.createObjectURL(evt.target.files[0]))
        }
    }

  return (
    <Card>
        <Card.Body>
            <h3 className='mb-5'>{isEdit ? 'Update package' : 'Create package'}</h3>
            <hr/>
            <Form>
                <Form.Row>
                    <Col md={8} className='pr-8'>
                        <Form.Group>
                            <Form.Label>Key<span className="text-danger">*</span></Form.Label>
                            <Form.Control placeholder="Key"
                                        value={packageData?.name}
                                        name="name" onChange={handleChange} as="select">
                                <option/>
                                {PackagesEnum?.map((item: string) => <option
                                    key={item}
                                    value={item}>{item}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Display name<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="displayName" onChange={handleChange}
                                        defaultValue={packageData?.displayName}/>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group className='col-6'>
                                <Form.Check label="Active" type="checkbox" name="active"
                                            onChange={(e:any) => setPackageData({...packageData, isActive: e.target.checked})}
                                            checked={packageData.isActive}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label='Transparent Pricing' type="checkbox" name="isTransparentPricing"
                                            onChange={(e:any) => setPackageData({...packageData, isTransparentPricing: e.target.checked})}
                                            checked={packageData.isTransparentPricing}/>
                            </Form.Group>
                        </Form.Row>
                        <h1 className="font-size-h1 text-black mb-5 text-center">GENERAL</h1>
                        <Form.Row>
                            <Form.Group className='col-6'>
                                <Form.Label>Monthly Price (€)<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="monthlyPrice" onChange={handleChange}
                                            defaultValue={packageData.monthlyPrice}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Annual Price (€)<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="annualPrice" onChange={handleChange}
                                            defaultValue={packageData.annualPrice}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Admin accounts<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="nbMasters" onChange={handleChange}
                                            defaultValue={packageData.nbMasters}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Manager accounts<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="nbEmployees" onChange={handleChange}
                                            defaultValue={packageData.nbEmployees}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Position<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="position" onChange={handleChange}
                                            defaultValue={packageData.position}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Fee<span className="text-danger">*</span> Currently: {calculateFee(packageData.fee)}</Form.Label>
                                <Form.Control type='number' step="0.01" min={0} max={1} name="fee" onChange={handleChange}
                                            value={packageData.fee ?? 0.00}/>
                            </Form.Group>
                        </Form.Row>
                        <h1 className="font-size-h1 text-black mb-5 text-center">FUNCTIONAL</h1>
                        <Form.Row>
                            <Form.Group className='col-6'>
                                <Form.Check label='Long-term Collaborations' type='checkbox' name="longTermCollaborations"
                                            onChange={(e:any) => setPackageData({...packageData, longTermCollaborations: e.target.checked})}
                                            checked={packageData.longTermCollaborations}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Barter Deals" type="checkbox" name="active"
                                            onChange={(e:any) => setPackageData({...packageData, allowBarterDeals: e.target.checked})}
                                            checked={packageData.allowBarterDeals}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Allow Private campaigns" type="checkbox" name="allowPrivateCampaigns"
                                            onChange={(e:any) => setPackageData({...packageData, allowPrivateCampaigns: e.target.checked})}
                                            checked={packageData.allowPrivateCampaigns}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="AI Briefing" type="checkbox" name="allowBriefingAI"
                                            onChange={(e:any) => setPackageData({...packageData, allowBriefingAI: e.target.checked})}
                                            checked={packageData.allowBriefingAI}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Creator Sourcing<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.creatorSourcing}
                                            name="creatorSourcing" onChange={handleChange} as="select">
                                    <option/>
                                    {CreatorSourcingPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Publication period length in weeks<span className="text-danger">*</span></Form.Label>
                                <Form.Control type='number' name="publicationPeriodLength" onChange={handleChange}
                                            value={packageData.publicationPeriodLength ?? 0}/>
                            </Form.Group>
                        </Form.Row>
                        <h1 className="font-size-h1 text-black mb-5 text-center">VISUAL</h1>
                        <Form.Row>
                            <Form.Group className='col-6'>
                                <Form.Label>Social media platforms<span className="text-danger">*</span></Form.Label>
                                <Form.Control 
                                            value={packageData?.socialMediaPlatforms}
                                            name="socialMediaPlatforms" onChange={handleChange} as="select">
                                    <option/>
                                    {SocialPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Influencer search & invite<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.searchAndInvite}
                                            name="searchAndInvite" onChange={handleChange} as="select">
                                    <option/>
                                    {SearchPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                                <Form.Group className='col-6'>
                                    <Form.Label>Briefing module<span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="Name" 
                                                value={packageData?.briefingModule}
                                                name="briefingModule" onChange={handleChange} as="select">
                                        <option/>
                                        {BriefingPackageEnum?.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className='col-6'>
                                    <Form.Label>Booking module<span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="Name" 
                                                value={packageData?.bookingModule}
                                                name="bookingModule" onChange={handleChange} as="select">
                                        <option/>
                                        {BookPackageEnum?.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className='col-6'>
                                    <Form.Label>Manage module<span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="Name" 
                                                value={packageData?.manageModule}
                                                name="manageModule" onChange={handleChange} as="select">
                                        <option/>
                                        {ManagePackageEnum?.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className='col-6'>
                                    <Form.Label>Measure module<span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="Name" 
                                                value={packageData?.measureModule}
                                                name="measureModule" onChange={handleChange} as="select">
                                        <option/>
                                        {MeasurePackageEnum?.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className='col-6'>
                                    <Form.Label>Message module<span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="Name" 
                                                value={packageData?.messageModule}
                                                name="messageModule" onChange={handleChange} as="select">
                                        <option/>
                                        {MessagePackageEnum?.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Briefing feedback<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.briefingFeedback}
                                            name="briefingFeedback" onChange={handleChange} as="select">
                                    <option/>
                                    {CustomerPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Influencer recommendation<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.influencerRecommendation}
                                            name="influencerRecommendation" onChange={handleChange} as="select">
                                    <option/>
                                    {CustomerPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Platform Training<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.platformTraining}
                                            name="platformTraining" onChange={handleChange} as="select">
                                    <option/>
                                    {TechSupportPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Campaign Feedback<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.campaignFeedback}
                                            name="campaignFeedback" onChange={handleChange} as="select">
                                    <option/>
                                    {TechSupportPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Label>Success Manager<span className="text-danger">*</span></Form.Label>
                                <Form.Control placeholder="Name" 
                                            value={packageData?.successManager}
                                            name="successManager" onChange={handleChange} as="select">
                                    <option/>
                                    {SuccessPackageEnum?.map((item: string) => <option
                                        key={item}
                                        value={item}>{getCorrectEnums(item)}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Influencer contracts" type="checkbox" name="influencerContracts"
                                            onChange={(e:any) => setPackageData({...packageData, influencerContracts: e.target.checked})}
                                            checked={packageData.influencerContracts}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Influencer payouts" type="checkbox" name="influencerPayouts"
                                            onChange={(e:any) => setPackageData({...packageData, influencerPayouts: e.target.checked})}
                                            checked={packageData.influencerPayouts}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label='External Collaboration' type='checkbox' name="externalCollaboration"
                                            onChange={(e:any) => setPackageData({...packageData, externalCollaboration: e.target.checked})}
                                            checked={packageData.externalCollaboration}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Tech support" type="checkbox" name="techSupport"
                                            onChange={(e:any) => setPackageData({...packageData, techSupport: e.target.checked})}
                                            checked={packageData.techSupport}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Profile analytics" type="checkbox" name="profileAnalytics"
                                            onChange={(e:any) => setPackageData({...packageData, profileAnalytics: e.target.checked})}
                                            checked={packageData.profileAnalytics}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Influencer reviews" type="checkbox" name="reviews"
                                            onChange={(e:any) => setPackageData({...packageData, reviews: e.target.checked})}
                                            checked={packageData.reviews}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Team collaboration" type="checkbox" name="teamCollaboration"
                                            onChange={(e:any) => setPackageData({...packageData, teamCollaboration: e.target.checked})}
                                            checked={packageData.teamCollaboration}/>
                            </Form.Group>
                            <Form.Group className='col-6'>
                                <Form.Check label="Allow campaign transfers" type="checkbox" name="allowCampaignTransfers"
                                            onChange={(e:any) => setPackageData({...packageData, allowCampaignTransfers: e.target.checked})}
                                            checked={packageData.allowCampaignTransfers}/>
                            </Form.Group>
                        </Form.Row>
                    </Col>

                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Upload photo</Form.Label>
                            <Form.Control accept=".jpg, .jpeg, .png" type="file" onChange={handleFileInput}/>
                            {imagePath &&
                                <Image className='mt-5' width={300} height={250} alt={imagePath} src={imagePath}/>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button onClick={isEdit ? updatePackage : createPackage} variant="primary">
                    Save
                </Button>
            </Form>
        </Card.Body>
    </Card>
  )
}
