import * as React from 'react';
import {OfferFilter} from './OfferFilter';
import {OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Offer} from '../../../model/offer/Offer';
import {toast} from 'react-toastify';
import {OfferService} from '../OfferService';
import {Pagination} from '../../../shared/components/Pagination';
import {formatDate} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {FaCheck, FaTimes} from "react-icons/fa";
import {FiInfo} from "react-icons/all";

export function OfferList() {
    const [offers, setOffers] = useState<Offer[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        id: "",
        date: "",
        influencerId: "",
        campaignId: "",
        status: ""
    })

    function getOffer(state: any, updatePage?: number) {
        OfferService.getOffers({...state, page: !!updatePage ? updatePage: state.page})
            .then((data) => {
                setOffers(data?.data);
                setState({...state, page: data.page})
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
        }
        
    useEffect(() => {
        getOffer(state);
    }, [state.campaignId, state.influencerId]);

    const handlePages = (updatePage: number) => {
        getOffer(state, updatePage)
    };

    return (
        <>
            <div className="card card-custom mb-5 p-4">
                <OfferFilter handleState={getOffer} setState={setState} state={state}/>
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Offer ID</th>
                            <th>Company</th>
                            <th>Influencer</th>
                            <th>Campaign</th>
                            <th>Status</th>
                            <th>Agency?</th>
                            <th>Current?</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {offers.map((e) => (
                        <tr key={e?.id}>
                            <td>{formatDate(e.createdAt)}</td>
                            <td>{e.id}</td>
                            <td><ImpersonateEye
                                id={e?.campaign?.company?.createdBy}/>{e?.campaign?.company?.displayName}</td>
                            <td><ImpersonateEye
                                id={e?.user?.id ?? 0}/>{e?.user?.displayName}<br/>{e?.user?.firstName ?? '-'} {e?.user?.lastName}
                            </td>
                            <td>{e?.campaign?.id}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="campaign-tooltip">
                                                    {e?.campaign?.title}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger></td>
                            <td><span className={`status ${e?.status}`}>{e?.status}</span></td>
                            <td>{e?.user?.agencyCompany?.id ? e?.user?.agencyCompany?.id :
                                <span className="word-break text-danger"><FaTimes/></span>}
                                {e?.user?.agencyCompany?.id && <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="campaign-tooltip">
                                                    {e?.user?.agencyCompany?.displayName}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>}
                            </td>
                            <td>{e?.isCurrent ? <span className="word-break text-primary"><FaCheck/></span> :
                                <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>
                                <NavLink className="btn btn-primary" to={{
                                    pathname: '/offers/edit',
                                    state: {id: e.id, offer: e}
                                }}>Edit</NavLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}
