import { IPackages } from '../../model/package/IPackage';
import {requests} from '../../services';
import {OVERRIDE_URL, PACKAGES_URL} from '../../utils/Endpoints';

export const PackagesService = {
    getPackages: (params: {page:number, perPage: number}) => requests.get(`${PACKAGES_URL}`, params),
    getPackage: (id: number) => requests.get(`${PACKAGES_URL}/${id}`),
    putPackage: (params: IPackages, id: number) => requests.put(`${PACKAGES_URL}/${id}`, params),
    createPackage: (params: IPackages) => requests.post(`${PACKAGES_URL}`, params),
    togglePackage: (id: number) => requests.put(`${PACKAGES_URL}/toggle/${id}`, {}),
    changePackage: (packageId: number, companyId: number) => requests.post(`${PACKAGES_URL}/change/company`, {packageId: packageId, companyId: companyId}),
    getOverride: (id: number) => requests.get(`${OVERRIDE_URL}/${id}`),
    postOverride: (id: number, params: Partial <IPackages>) => requests.post(`${OVERRIDE_URL}/${id}`, params),
    putOverride: (id: number, params: Partial <IPackages>) => requests.put(`${OVERRIDE_URL}/${id}`, params),
    deleteOverride: (id: number) => requests.delete(`${OVERRIDE_URL}/${id}`),
    getPackagesLog: (params: {page:number, perPage: number}) => requests.get(`${PACKAGES_URL}/change/log`, params),
}
