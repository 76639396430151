export enum AdminAclEnum {
    ADMIN = 'admin',
    USERS = 'users',
    INFLUENCERS = 'influencers',
    CAMPAIGNS = 'campaigns',
    SUPERVISORS = 'supervisors',
    OFFERS = 'offers',
    ACCESS_RIGHTS = 'access_rights',
    PACKAGES = 'packages',
    EXPORT = 'export',
    EMAILING = 'emailing',
    BUDGET = 'budget_overview',
    AGENCY = 'agency',
    REPORTING = 'reporting',
    ONBOARDING = 'onboarding',
    STATISTICS = 'statistics',
    COMPANY = 'company',
    INVOICES = 'invoices',
    PLATFORM = 'platform',
    USER_PLATFORM_LOGS = 'user_platform_logs',
}
