import {Button, Card, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import React, {SetStateAction, useState} from 'react';
import {ICampaignReview} from '../../../model/campaign/ICampaignReview';
import {DashboardService} from '../DashboardService';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {ReviewModal} from './ReviewModal';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {formatDate} from "../../../utils/Functions";
import {FiInfo} from "react-icons/all";
import {priceSplitter} from "../../../helpers/NumberFormater";

export interface InCampaignReview {
    campaignReview: ICampaignReview[];
    setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>
}

export function CampaignReview({campaignReview, setTriggerUpdate}: InCampaignReview) {
    const [modalShow, setModalShow] = useState(false);
    const [currentCampaignId, setCurrentCampaignId] = useState(0)
    const [declineReason, setDeclineReason] = useState({
        declineReason: ''
    })
    const handleSubmit = (campaignId: number, status: string) => {
        DashboardService.campaignApprovalAction(campaignId, {
            ...declineReason,
            approvalStatus: status
        }).then(response => {
            setTriggerUpdate(prev => !prev);
            SuccessToast('Success')
        }).catch(error => ErrorToast(error));
    }

    return (
        <Card className="p-4 mt-3">
            <h5 className='p-3'>Campaigns for review</h5>
            
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Brand</th>
                        <th>Email</th>
                        <th>Budget</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {campaignReview?.sort((a, b) => b.id - a.id)?.map((campaign) => {
                    return (
                        <tr key={campaign?.id}>
                            <td>{campaign?.title}</td>
                            <td>{formatDate(campaign.publishedAt)}</td>
                            <td>
                                {campaign?.company?.profilePhoto?.thumbnail ? <img alt='' src={campaign?.company?.profilePhoto?.thumbnail} style={{width: 35, height: 35}}/>
                                    : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}}/>
                                }
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {campaign?.company?.displayName}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <ImpersonateEye id={campaign.user?.id ?? 0}/>
                                {campaign?.user?.email}
                            </td>
                            <td>{priceSplitter(campaign?.grossBudget)} €</td>
                            <td>
                                <span className={`status ${campaign?.status}`}>
                                    {campaign?.status === 'published' ? 'public' : campaign.status}
                                </span>
                            </td>
                            <td>
                                <Button className="mr-xl-2 mb-2 align-top"
                                        size="sm"
                                        onClick={() => handleSubmit(campaign?.id, 'approved')}>Approve</Button>
                                <Button variant="danger"
                                        size="sm"
                                        onClick={() => {
                                            setCurrentCampaignId(campaign?.id)
                                            setModalShow(true)
                                        }}>Decline</Button>
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
            </Table>
            <ReviewModal
                id={currentCampaignId}
                handleSubmit={handleSubmit}
                declineReason={declineReason.declineReason}
                setDeclineReason={setDeclineReason}
                show={modalShow}
                closeModal={() => setModalShow(false)}/>
        </Card>
    )
}
