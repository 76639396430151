import React, {useEffect, useState} from 'react';
import {UserService} from '../../user/UserService';
import {toast} from 'react-toastify';
import {User} from '../../../model/user/User';
import {Button, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import Flag from 'react-world-flags';
import {getCountryName} from '../../../helpers/CorrectCountryNames';
import {formatDate} from '../../../utils/Functions';
import {ImMobile} from 'react-icons/all';
import {Pagination} from '../../../shared/components/Pagination';
import {AdminAccessRightsModal} from './AdminAccessRightsModal';

export default function AdminList() {
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        role: 'role_admin'
    })
    const [selectedUserData, setSelectedUserData] = useState({
        id: 0,
        adminAcl: [''],
    })
    const [updateList, setUpdateList] = useState(false);
    const [showAclModal, setShowAclModal] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [admins, setAdmins] = useState<User[]>([]);

    useEffect(() => {
        UserService.getUsers(state)
            .then((data) => {
                setAdmins(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }, [JSON.stringify(state), updateList]);

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage});
    };

    const openModal = (id: number, selectedUserAcl: string[]) => {
        setSelectedUserData(prevState => ({
            ...prevState, id, adminAcl: selectedUserAcl
        }))
        setShowAclModal(true)
    }

    const closeModal = () => {
        setShowAclModal(false);
        setUpdateList(prevState => !prevState);
    }
    return (
        <>
            <AdminAccessRightsModal key={selectedUserData.id} show={showAclModal} closeModal={closeModal} id={selectedUserData.id} aclArr={selectedUserData.adminAcl}/>
            <h3 className="text-dark fw-bold fs-2 mb-4">Admin List</h3>
            <div className="card card-custom mb-5 p-4">
                <Table responsive className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                    <tr className="even">
                        <th>ID</th>
                        <th>Role</th>
                        <th>Display name</th>
                        <th>Country</th>
                        <th>Email</th>
                        <th>Last login</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {admins?.map((e) => (
                        <tr key={e.id + e.email}>
                            <td>{e.id}</td>
                            <td>{e.userType ?? '-'}</td>
                            <td>{e.displayName}<br/>{e.firstName ?? '-'} {e.lastName}</td>
                            <td><Flag className='country-flag cursor-pointer' code={e?.contact?.country}
                                      title={getCountryName(e.contact.country)}/></td>
                            <td>{e.email}</td>
                            <td>
                                {formatDate(e.lastLogin as string) ?? '-'}<br/> IP:{e.lastLoginIp ?? '-'}
                                {e.hasMobileLogin && (
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip
                                                        id="impressions-tooltip">
                                                        {e?.mobileDate && formatDate(e?.mobileDate)}
                                                    </Tooltip>}>
                                        <ImMobile size={18} className="cursor-pointer ml-1"/>
                                    </OverlayTrigger>
                                )}
                            </td>
                            <td>
                                <Button size="sm"
                                        variant="primary"
                                        onClick={() => openModal(e?.id ?? 0, e?.adminAcl ?? [])}>
                                    Edit ACL
                                </Button></td>
                        </tr>
                    ))}
                    </tbody>
                    {admins.length < 15 && !!admins.length && (
                        <div className='h-180px'></div>
                    )}
                </Table>
                {totalPages > 1 &&
                    <div className="w-100">
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    </div>
                }

            </div>
        </>
    )
}