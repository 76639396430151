/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from "react";
import objectPath from "object-path";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useHtmlClassService} from "..";
import {toAbsoluteUrl} from "../../../helpers";
import {AsideMenu} from "./aside-menu/AsideMenu";
import {Brand} from "../brand/Brand";
import {KTUtil} from "../../../assets/js/components/util";
import {NavLink, useHistory} from "react-router-dom";
import {FaChartBar, FaCreditCard, FaUser, FaUserAstronaut, FaUserEdit, FaUserShield} from "react-icons/fa";
import {useDispatch} from "react-redux";
import {actions} from "../../../store/authentication/authRedux";
import {hasAccessRight} from "../../../utils/Functions";
import {AdminAclEnum} from "../../../enum/AdminAclEnum";

export function Aside() {

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            asideClassesFromConfig: uiService.getClasses("aside", true),
            asideSecondaryDisplay: objectPath.get(
                uiService.config,
                "aside.secondary.display"
            ),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            extrasSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            extrasNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            extrasQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            extrasQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            extrasLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            extrasUserDisplay: objectPath.get(
                uiService.config,
                "extras.user.display"
            ),
        };
    }, [uiService]);
    const history = useHistory()
    const dispatch = useDispatch()

    const tabs = {
        tabId1: "kt_aside_tab_1",
        tabId2: "kt_aside_tab_2",
    };


    const handleTabChange = (id) => {
        setActiveTab(id);
        const asideWorkspace = KTUtil.find(
            document.getElementById("kt_aside"),
            ".aside-secondary .aside-workspace"
        );
        if (asideWorkspace) {
            KTUtil.scrollUpdate(asideWorkspace);
        }
    };

    const [activeTab, setActiveTab] = useState(tabs.tabId1);

    const logout = () => {
        dispatch(actions.logout());
        localStorage.clear();
        history.push('/');
    };

    return (
        <>
            {/* begin::Aside */}
            <div
                id="kt_aside"
                className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
            >
                {/* begin::Primary */}
                <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
                    <Brand/>
                    {/* begin::Nav Wrapper */}
                    <div
                        className="aside-nav d-flex flex-column align-items-center justify-content-between flex-column-fluid pt-5 scroll scroll-pull">
                        {/* begin::Nav */}
                        <ul className="list-unstyled flex-column" role="tablist">

                            {/* begin::Item */}
                            <li className="nav-item mb-3" title="Dashboard Card">
                                <OverlayTrigger placement="right"
                                                overlay={<Tooltip id="latest-project">Dashboard Card</Tooltip>}>
                                    <NavLink exact to="/" className="nav-link btn btn-icon btn-clean btn-lg">
                                        <span className="svg-icon svg-icon-lg">
                                           <img alt="layout-icon"
                                                src={toAbsoluteUrl("/media/icons/Layout/Layout-4-blocks.svg")}/>
                                        </span>
                                    </NavLink>
                                </OverlayTrigger>
                            </li>
                            {/* end::Item */}

                            {/* begin::Item */}
                            {hasAccessRight(AdminAclEnum.USERS) &&
                                <li className="nav-item mb-3" title="Users">
                                    <OverlayTrigger placement="right"
                                                    overlay={<Tooltip id="metronic-features">Users</Tooltip>}>
                                        <NavLink exact to="/user" className={`nav-link btn btn-icon btn-clean btn-lg`}>
                                        <span className="svg-icon svg-icon-lg">
                                          <FaUser/>
                                        </span>
                                        </NavLink>
                                    </OverlayTrigger>
                                </li>
                            }
                            {/* end::Item */}

                            {/* begin::Item */}
                            {hasAccessRight(AdminAclEnum.REPORTING) &&
                                <li className="nav-item mb-3" title="Latest Reports">
                                    <OverlayTrigger placement="right"
                                                    overlay={<Tooltip id="latest-reports">Reporting</Tooltip>}>
                                        <NavLink to="/report" className="nav-link btn btn-icon btn-clean btn-lg">
                                        <span className="svg-icon svg-icon-lg">
                                          <FaChartBar/>
                                        </span>
                                        </NavLink>
                                    </OverlayTrigger>
                                </li>
                            }
                            {/* end::Item */}

                            {/* begin::Item */}
                            {hasAccessRight(AdminAclEnum.BUDGET) &&
                                <li className="nav-item mb-3" title="Budget overview">
                                    <OverlayTrigger placement="right"
                                                    overlay={<Tooltip id="latest-reports">Budget overview</Tooltip>}>
                                        <NavLink to="/campaign/budget"
                                                 className="nav-link btn btn-icon btn-clean btn-lg">
                                        <span className="svg-icon svg-icon-lg">
                                          <FaCreditCard/>
                                        </span>
                                        </NavLink>
                                    </OverlayTrigger>
                                </li>
                            }
                            {/* end::Item */}

                            {/* begin::Item */}
                            {hasAccessRight(AdminAclEnum.INFLUENCERS) &&

                                <li className="nav-item mb-3" title="Budget overview">
                                    <OverlayTrigger placement="right"
                                                    overlay={<Tooltip id="latest-reports">Influencers</Tooltip>}>
                                        <NavLink exact to="/user/influencers"
                                                 className={`nav-link btn btn-icon btn-clean btn-lg`}>
                                        <span className="svg-icon svg-icon-lg">
                                          <FaUserAstronaut/>
                                        </span>
                                        </NavLink>
                                    </OverlayTrigger>
                                </li>
                            }
                            {/* end::Item */}
                            {/* begin::Item */}
                            {hasAccessRight(AdminAclEnum.ADMIN) &&
                                <li className="nav-item mb-3" title="Admins">
                                    <OverlayTrigger placement="right"
                                                    overlay={<Tooltip id="latest-reports">Admins</Tooltip>}>
                                        <NavLink to="/admins" className={`nav-link btn btn-icon btn-clean btn-lg`}>
                                        <span className="svg-icon svg-icon-lg">
                                          <FaUserShield/>
                                        </span>
                                        </NavLink>
                                    </OverlayTrigger>
                                </li>
                            }
                            {/* end::Item */}

                        </ul>
                        {/* end::Nav */}
                        <div className="mb-6">
                            <Button onClick={logout} className="cursor-pointer" variant="outline-danger"
                            >Sign out</Button>
                        </div>
                    </div>
                    {/* end::Nav Wrapper */}

                    {/* begin::Footer */}
                    <div
                        className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                        {/* begin::Aside Toggle */}
                        {layoutProps.asideSecondaryDisplay &&
                            layoutProps.asideSelfMinimizeToggle && (
                                <>
                                    <OverlayTrigger
                                        transition={false}
                                        placement="right"
                                        overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                                    >
                                        <span
                                            className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                                            id="kt_aside_toggle"
                                        >
                                            <i className="ki ki-bold-arrow-back icon-sm"/>
                                        </span>
                                    </OverlayTrigger>
                                </>
                            )}
                        {/* end::Aside Toggle */}

                    </div>
                    {/* end::Footer */}
                </div>
                {/* end::Primary */}

                {layoutProps.asideSecondaryDisplay && (
                    <>
                        {/* begin::Secondary */}
                        <div className="aside-secondary d-flex flex-row-fluid">
                            {/* begin::Workspace */}
                            <div className="aside-workspace scroll scroll-push my-2">
                                <div className="tab-content">
                                    <AsideMenu isActive={activeTab === tabs.tabId1}/>
                                </div>
                            </div>
                            {/* end::Workspace */}
                        </div>
                        {/* end::Secondary */}
                    </>
                )}
            </div>
            {/* end::Aside */}
        </>
    );
}
